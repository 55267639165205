'use strict';

var $doc;

// sfccGa will refer to Universal Analytics
// ga4 will refer to Google Analytics 4
var ga4 = require('./ga4');

/**
 * Get the current page type as set from app.js
 * Fallback to the pageContext set in page decorators
 * @returns {String|Boolean} of page type, false if no page type defined
 */
function getPageType() {
    var pageType = 'app' in window && 'page' in app && app.page.type;
    if (!pageType) {
        pageType = 'pageContext' in window && 'type' in pageContext && pageContext.type;
    }

    return pageType;
}

/**
 * Setup Google Analytics 4 tracking, dataLayer, and page view events
 * Relies on the SitePreference: googleAnalyticsVersionsEnabled
 */
function initGoogleAnalytics4() {
    // exit early if not enabled
    if (window.siteGaData.versionsEnabled.indexOf('ga4') === -1) { return; }

    $doc.on('ga.producttiles', ga4.view_item_list);
    $doc.on('ga.producttilesajax', ga4.productTilesAjax);
    $doc.on('ga.producttile.click', ga4.select_item);
    $doc.on('ga.productdetail', ga4.view_item);
    $doc.on('ga.addtowishlist', ga4.add_to_wishlist);
    $doc.on('ga.quickview', ga4.quickview);
    $doc.on('ga.addtocart', ga4.add_to_cart);
    $doc.on('ga.removefromcart', ga4.remove_from_cart);
    $doc.on('ga.cart', ga4.view_cart);
    $doc.on('ga.checkoutstep', ga4.checkoutStep);
    $doc.on('ga.purchase', ga4.purchase);
    $doc.on('ga.viewproductslot', ga4.viewproductslot);
    $doc.on('ga.applygiftcard', ga4.applyGiftCard);
    $doc.on('ga.shopthelook', ga4.shopTheLook);
    $doc.on('ga.recommenderslider', ga4.recommenderSliderEngagement);
    $doc.on('ga.checkoutengagement', ga4.checkoutEngagement);

    // Report all slot list/promotion on page load
    $doc.trigger('ga.viewproductslot');

    /**
     * Handle custom events, but wait until we're doc ready to make sure elements exist in DOM
     */
    $doc.ready(function() {
        /** Cart engagements */
        $doc.on('click', '#cart-table .form-qty-select', function() {
            ga4.cartEngagement('Qty', 'Item List');
        });

        $doc.on('click', '.cart-promo-code .coupon-remove', function() {
            var $link = $(this);
            var clickText = $link.text().replace(/\n/g, '');
            ga4.cartEngagement(clickText, 'Cart Promo Code');
        });

        $doc.on('click', '.scp-main .co-mini-cart-help-info a', function() {
            var $link = $(this);
            var clickText = $link.text();
            var clickSection = $link.closest('section').find('h3').text().replace(/\n/g, '');
            ga4.cartEngagement(clickText, clickSection);
        });

        /** Checkout engagements */
        $doc.on('change', 'input.sameAsShippingAddress', function() {
            var $input = $(this);
            var clickText = $input.closest('.form-row').find('label').text().replace(/\n/g, '');
            var checkoutStep = $('.active .co-indicator').text();
            ga4.checkoutEngagement(null, clickText, 'Billing Address', checkoutStep);
        });

        $doc.on('change', '.co-gift input', function() {
            var clickText = $('.co-gift label').text().replace(/\n/g, '');
            var checkoutStep = $('.active .co-indicator').text();
            ga4.checkoutEngagement(null, clickText, 'Checkout Gift', checkoutStep);
        });

        $doc.on('click', '.co-main .co-completed-steps a', function() {
            var $link = $(this);
            var clickText = $link.children().not('.visually-hidden').text();
            var sectionTitle = $link.parents('.step-row').find('.label-details .label').text();
            var checkoutStep = $('.active .co-indicator').text();
            ga4.checkoutEngagement(null, clickText, sectionTitle, checkoutStep);
        });

        $doc.on('click', '.co-main .co-form-header-group a', function () {
            var $link = $(this);
            if ($link.hasClass('co-apofpo-link')) {
                return;
            }
            var clickText = $link.text();
            var checkoutStep = $('.active .co-indicator').text();
            var clickSection = $link.parents('.co-form-header-group').find('h2, h3').text().replace(/\n/g, '');
            ga4.checkoutEngagement(null, clickText, clickSection, checkoutStep);
        });

        $doc.on('click', '.co-main .return-to-previous-link', function() {
            var $link = $(this);
            var clickText = $link.text();
            var checkoutStep = $('.active .co-indicator').text();
            ga4.checkoutEngagement(null, clickText, 'Checkout Footer', checkoutStep);
        });

        $doc.on('click', '.co-main .toggle-cart-items', function() {
            var $link = $(this);
            var clickText = $link.text();
            var checkoutStep = $('.active .co-indicator').text();
            ga4.checkoutEngagement(null, clickText, 'Order Summary', checkoutStep);
        });

        $doc.on('click', '.co-main .co-mini-cart-help-info a', function() {
            var $link = $(this);
            var clickText = $link.text();
            var clickSection = $link.closest('section').find('h3').text().replace(/\n/g, '');
            var checkoutStep = $('.active .co-indicator').text();
            ga4.checkoutEngagement(null, clickText, clickSection, checkoutStep);
        });


        /** Search tile engagements */
        $doc.on('click', '.search-result-items .product-tile', function() {
            var $tile = $(this);
            var searchTerm = $('.search-term').text();
            var productName = $tile.find('.product__name').text().replace(/\n/g, '');
            if (searchTerm) {
                ga4.searchResultClick(searchTerm, productName);
            }
        });

        /** Navigation menu engagements */
        $doc.on('click', '[data-link-classification=\'top level\']', function() {
            var $link = $(this);
            var classification = $link.data('link-classification');
            var division = $link.data('link-division');
            var category = $link.data('link-category');
            var text = $link.data('link-text');
            var type = $link.data('link-type');
            ga4.topNavigationClick(classification, division, category, text, type);
        });

        /** Utility navigation engagements */
        $doc.on('click', '[data-link-classification=\'utility\']', function() {
            var $link = $(this);
            var classification = $link.data('link-classification');
            var division = $link.data('link-division');
            var category = $link.data('link-category');
            var text = $link.data('link-text');
            var type = $link.data('link-type');
            ga4.navigationUtilityClick(classification, division, category, text, type);
        });

        $doc.on('click', '.returning-customer-wrapper .sign-in-button a', function() {
            var $link = $(this);
            var text = $link.text().replace(/\n/g, '');
            ga4.navigationUtilityClick('utility', undefined, 'account access', text, 'text');
        });

        /** My account engagements */
        $doc.on('click', '.mao-link', function() {
            var $link = $(this);
            var accountCategory = $link.find('.mao-asset-title').text().replace(/\n/g, '');
            var clickText = $link.find('.mao-span-link').text();
            ga4.myAccountActivityClick(accountCategory, 'Account Info', clickText);
        });

        $doc.on('click', '.am-content a', function() {
            var $link = $(this);
            var clickText = $link.text();
            var accountCategory = $link.parents('.am-expander').find('.am-title').text().replace(/\n/g, '');
            ga4.myAccountActivityClick(accountCategory, 'My account menu', clickText, clickText);
        });

        $doc.on('click', '.order-history-link', function() {
            var $link = $(this);
            var clickText = $link.text();
            var accountSubcategory = $('.am-content .is-actve').text().replace(/\n/g, '');
            var accountSubection = $('.content-asset h2').text().replace(/\n/g, '');
            ga4.myAccountActivityClick(undefined, undefined, clickText, accountSubcategory, accountSubection);
        });

        $doc.on('click', '.mao-primary-content .address-edit, .mao-primary-content .address-delete, .mao-primary-content .address-create', function() {
            var $link = $(this);
            var clickText = $link.text().replace(/\n/g, '');
            ga4.myAccountActivityClick(undefined, undefined, clickText, 'Addresses', 'Addresses');
        });

        $doc.on('click', '.mao-primary-content a, .mao-primary-content button', function() {
            var $link = $(this);
            if (
                $link.hasClass('mao-link') || 
                $link.hasClass('order-history-link') || 
                $link.hasClass('address-edit') || 
                $link.hasClass('address-delete') ||
                $link.hasClass('address-create')) {
                return;
            }

            var $container = $link.parents('.mao-primary-content > *');
            var $siblings = $('.mao-primary-content > *');
            var clickText = $link.text().replace(/\n/g, '');
            var clickPosition = $link.index();
            var accountSubcategory = $('.am-content .is-active').text().replace(/\n/g, '');
            var accountSubsection;

            $siblings.each(function() {
                var $this = $(this);
                if ($container.index() > $this.index() && $this.is('h1, h2')) {
                    accountSubsection = $this.text().replace(/\n/g, '');
                }
            });

            ga4.myAccountActivityClick(undefined, undefined, clickText, accountSubcategory, accountSubsection, clickPosition + 1);
        });
    });

    // Trigger an action on page load
    var pageType = getPageType();
    if (pageType) {
        window.siteGaData.pageType = pageType;

        switch (pageType) {
            case 'Cart':
                $doc.trigger('ga.cart');
                break;
            case 'orderconfirmation':
                $doc.trigger('ga.purchase');
                break;
            default:
                // Do nothing
                break;
        }
    }

}

$(function() {
    $doc = $(document);
    var siteGaData = {};

    try {
        siteGaData = JSON.parse($('.site-ga-data').text());
    } catch(e) {
        window.console.warn('site GA data could not be serialized for analytics');
        return;
    }

    window.siteGaData = $.extend(window.siteGaData, siteGaData);

    initGoogleAnalytics4();
});
