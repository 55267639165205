'use strict';

/**
 * Send event to ga4
 * @param {String} label
 * @param {Object} data
 */

function sendEvent(label, data) {
    window.dataLayer = window.dataLayer || [];
    
    /** Handle clearing of ecommerce event object */
    if ('ecommerce' in data) {
        window.dataLayer.push({ ecommerce: null });
    }

    /** Create a single event. 
     * We could use property spread, but unsure about support
     * and it appears Object.assign is polyfilled. 
     */
    window.dataLayer.push(Object.assign({
        event: label,
    }, data || {}));
}

/**
 * Set product attributes in session storage
 * @param {String} label
 * @param {String} productId
 * @param {String} data
 */
function setProductAttributeInStorage(label, productId, data) {
    if (!productId || !data) {
        return;
    }

    try {
        var sanitizedProductId = '' + productId;
        var normalizedProductId = sanitizedProductId.split('-')[0];
        window.sessionStorage.setItem(normalizedProductId + label, data);

        const prefix = "_ga4ss";
        var ga4ss = getCookie(prefix);
        var ga4ssLbls = (ga4ss == null) ? [] : ga4ss.split("|");
        if (ga4ssLbls.indexOf(label) < 0) {
        	ga4ssLbls.push(label);
        }
        var ga4ssLbl = getCookie(prefix + label);
        var ga4ssPrdIDs = (ga4ssLbl == null) ? [] : ga4ssLbl.split("|");
        if (ga4ssPrdIDs.indexOf(normalizedProductId) < 0) {
        	ga4ssPrdIDs.push(normalizedProductId);
        }
        document.cookie = prefix + "_" + normalizedProductId + label + "=" + encodeURIComponent(data) + "; path=/; Secure; SameSite=Strict";
        document.cookie = prefix + label + "=" + ga4ssPrdIDs.join("|") + "; path=/; Secure; SameSite=Strict";
        document.cookie = prefix + "=" + ga4ssLbls.join("|") + "; path=/; Secure; SameSite=Strict";
    } catch (err) {}
}

/**
 * Get a cookie by name
 * @param {string} key - The cookie name
 * @returns {string} the value of the cookie if found, null otherwise
 */
function getCookie(key) {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
        var tokens = cookies[i].split('=');
        var cookieKey = tokens[0].trim();
        if (cookieKey === key) {
            return tokens[1];
        }
    }
    return null;
}

/**
 * Get product attribute from session storage
 * @param {String} label
 * @param {String} productId
 */
function getProductAttributeFromStorage(label, productId) {
    if (!productId) {
        return;
    }
    
    try {
        var sanitizedProductId = '' + productId;
        var normalizedProductId = sanitizedProductId.split('-')[0];
        var productAttribute = window.sessionStorage.getItem(normalizedProductId + label);
        
        return productAttribute;
    } catch (err) {
        return null;
    }
}

/**
 * Set product list name in session storage
 * @param {String} productId
 * @param {String} productListName
 */
function setProductListInStorage(productId, productListName) {
    setProductAttributeInStorage('_product_list', productId, productListName)
}

/**
 * Get product list name out of session storage
 * @param {String} productId
 */
function getProductListFromStorage(productId) {
    return getProductAttributeFromStorage('_product_list', productId);
}

/**
 * Set product category in storage
 * @param {String} productId
 * @param {String} productCategory
 */
function setProductCategoryInStorage(productId, productCategory) {
    setProductAttributeInStorage('_product_category', productId, productCategory);
}

/**
 * Get product category from storage
 * @param {String} productId
 */
function getProductCategoryFromStorage(productId) {
    return getProductAttributeFromStorage('_product_category', productId);
}

/**
 * Set product load list number in storage
 * @param {String} productId
 * @param {String} loadListNumber
 */
function setProductLoadListNumberInStorage(productId, loadListNumber) {
    setProductAttributeInStorage('_product_load_list_number', productId, loadListNumber);
}

/**
 * Get product load list number from storage
 * @param {String} productId
 */
function getProductLoadListNumberFromStorage(productId) {
    return getProductAttributeFromStorage('_product_load_list_number', productId);
}

/**
 * Track PLP tile impressions
 * Pull data from window.siteGaData
 * Filter duplicates out that have already been reported
 * Use that data to populate the 'view_item_list' event
 */
function view_item_list(_e, product_list_name) {
    if (!window.siteGaData || !window.siteGaData.products) { return; }

    window.siteGaData.allProductsGA4 = window.siteGaData.allProductsGA4 || {};

    // check allProducts to prevent duplicate reporting
    var productsToReport = siteGaData.products.filter(function(product) {
        var uniqueID = getImpressionUniqueID(product);
        return !siteGaData.allProductsGA4.hasOwnProperty(uniqueID);
    });

    if (!productsToReport.length) { return; }

    // add reported products to allProducts to prevent them from being duplicated
    var productViews = [];
    productsToReport.forEach(function(product, index) {
        var uniqueID = getImpressionUniqueID(product);
        siteGaData.allProductsGA4[uniqueID] = product;
        productViews.push(getProductView(product, index));
    });

    sendEvent('view_item_list', {
        product_list_name: product_list_name,
        ecommerce: {
            items: productViews,
        }
    });

    // when page loads, iterate over sub grid products and report those.
    var subGridProducts = $('.site-ga-productgriddata').not('.tracked-ga4');
    if (subGridProducts && subGridProducts.length) {
        var currentGrid = subGridProducts.first();
        var productTilesData = {};

        try {
            productTilesData = JSON.parse(currentGrid.text());
        } catch(e) {
            log('product tiles subgrid GA data could not be serialized for analytics');
            return;
        }

        if (productTilesData && productTilesData.products) {
            siteGaData.products = productTilesData.products;
            // tag this node as tracked to prevent infinite loop of events
            currentGrid.addClass('tracked-ga4');
            view_item_list(product_list_name);
        }
    }
}

/**
 * Track PLP tile impressions loaded via Ajax
 * Usually happens in sorting, filtering, refining, or infinite scroll
 * Query the DOM for JSON generated in Search-Show Controller
 */
function productTilesAjax(_e, product_list_name) {
    var productTilesData = {};

    try {
        productTilesData = JSON.parse($('.site-ga-productgriddata').not('.tracked-ga4').last().text());
    } catch(e) {
        log('product tiles ajax GA data could not be serialized for analytics');
        return;
    }

    if (!productTilesData || !productTilesData.products || !window.siteGaData) { return; }

    window.siteGaData.allProductsGA4 = window.siteGaData.allProductsGA4 || {};
    
    productTilesData.products.forEach(function(product) {
        var uniqueID = getImpressionUniqueID(product);
        siteGaData.allProductsGA4[uniqueID] = product;
    });

    var items = [];
    for (var i = 0; i < productTilesData.products.length; i++) {
        items.push(getProductView(productTilesData.products[i]));
    }

    sendEvent('view_item_list', {
        product_list_name: product_list_name,
        ecommerce: {
            items: items
        }
    });
}

/**
 * Report Product Tile click impressions
 * @param {Event} event custom event
 * @param {HTML} tile tile element, with data attribute 'itemid'
 */
function select_item(event, tile, product_list_name) {
    if (!window.siteGaData || !window.siteGaData.allProductsGA4) { return; }

    var productID = tile.data().itemid;
    if (!productID) { return; }

    var productToReport = siteGaData.allProductsGA4[productID];
    if (!productToReport) { return; }

    /**
     * We do this to persist data for selected items
     * through order confirmation in our reporting.
     */
    setProductListInStorage(productToReport.id, product_list_name);
    setProductCategoryInStorage(productToReport.id, productToReport.category);
    setProductLoadListNumberInStorage(productToReport.id, productToReport.dimension2);

    var productView = getProductView(productToReport, 0, product_list_name);

    sendEvent('select_item', {
        product_list_name: product_list_name,
        ecommerce: {
            items: [ productView ]
        }
    });
}

/**
 * Track product detail page view
 * Pull data from window.siteGaData
 */
function view_item() {
    if (!window.siteGaData) { return; }

    var items = [];
    for (var i = 0; i < siteGaData.products.length; i++) {
        items.push(getProductView(siteGaData.products[i]));
    }

    sendEvent('view_item', {
        ecommerce: {
            items: items
        }
    });
}

/**
 * Track product detail page quickview from the PLP
 * Pull data from window.siteGaData
 */
function quickview() {
    if (!window.siteGaData) { return; }

    var quickviewData = {};

    try {
        quickviewData = JSON.parse($('.site-ga-quickviewdata').text());
    } catch(e) {
        log('add to cart GA data could not be serialized for analytics');
        return;
    }

    if (!quickviewData || !quickviewData.products) { return; }

    var items = [];
    for (var i = 0; i < quickviewData.products.length; i++) {
        items.push(getProductView(quickviewData.products[i]));
    }

    sendEvent('view_item', {
        ecommerce: { 
            items: items 
        }
    });
}

/**
 * Track add to wishlist ecommerce event
 * Pull data from window.siteGaData
 * @param {String} productID
 */
function add_to_wishlist(event, productID) {
    if (!window.siteGaData || !window.siteGaData.products) { return; }

    var wishlistProducts = siteGaData.products.filter(function(product) {
        return product.id === productID
    });

    addRemoveCartEvents('add_to_wishlist', wishlistProducts);
}

/**
 * Track add to cart ecommerce event
 * Query the DOM for JSON generated in Cart-AddProduct Controller
 * Use the data to populate the 'products'
 * Add data to the dataLayer which results in a GTM tag being fired
 * @param {String} gaData optional
 */
function add_to_cart(event, gaString, clickLocation) {
    var addToCartData = {};

    try {
        addToCartData = JSON.parse(gaString ? gaString : $('.site-ga-addtocartdata').first().text());
    } catch(e) {
        log('add to cart GA data could not be serialized for analytics');
        return;
    }

    if (!addToCartData || !addToCartData.products) { return; }

    addRemoveCartEvents('add_to_cart', addToCartData.products, clickLocation);
}

/**
 * Track remove from cart ecommerce event
 * Pull data from window.siteGaData
 */
function remove_from_cart() {
    if (!window.siteGaData || !window.siteGaData.removeProducts) { return; }
    addRemoveCartEvents('remove_from_cart', siteGaData.removeProducts);
}

function getClickLocation() {
    try {
        var params = new URLSearchParams(window.location.search);
        var clickLocation = params.get('clickLocation');
        return clickLocation || 'unknown';
    } catch (_err) {
        return 'unknown_error';
    } 
}

/**
 * Track cart views
 * Pull data from window.siteGaData
 */
function view_cart() {
    if (!window.siteGaData || !window.siteGaData.products) { return; }

    var freeShipping = window.siteGaData.freeShipping || {};
    var productViews =  window.siteGaData.products.map(function(product, index) {
        return getProductView(product, index);
    });
    var value = freeShipping.cartTotal || productViews.reduce(function(acc, product) {
        return acc + (product.price * product.quantity);
    }, 0);

    sendEvent('view_cart', {
        clickLocation: getClickLocation(),
        freeShippingThreshold: freeShipping.freeShippingThreshold,
        freeShippingAmountLeft: freeShipping.freeShippingAmountLeft,
        ecommerce: {
            currency: siteGaData.currency,
            value: value,
            items: productViews,
        }
    });
}

/**
 * Track checkout data
 * Pull data from window.siteGaData
 */
function checkoutStep(event, checkoutStep, stepInfo) {
    if (!window.siteGaData || !window.siteGaData.products || !window.siteGaData.checkout) { return; }

    var productViews = [];
    var step = checkoutStep ? checkoutStep : siteGaData.checkout.actionField.step;

    siteGaData.products.forEach(function(product, index) {
        var productView = getProductView(product, index);
        productViews.push(productView);
    });

    var value = productViews.reduce(function(acc, product) {
        /** We need to normalize to avoid JS floating point issues */
        var price = product.price * 100;
        var quantity = product.quantity;
        var totalPrice = price * quantity;
        return acc + totalPrice;
    }, 0) / 100;


    let location;
    let threshold;
    let remainingAmountUntilThreshold;
    if(siteGaData.checkout.actionField.hasOwnProperty('option')){
        const optionalData = siteGaData.checkout.actionField.option;
        location =  optionalData.hasOwnProperty('clickLocation') ? optionalData.clickLocation : undefined;
        threshold = optionalData.hasOwnProperty('freeShippingThresholdMet') ? optionalData.freeShippingThresholdMet : undefined;
        remainingAmountUntilThreshold = optionalData.hasOwnProperty('freeShippingAmountLeft') ? optionalData.freeShippingAmountLeft : undefined;
    }

    var checkoutData = {
        event: '',
        step: step,
        stepInformation: stepInfo ? stepInfo : undefined,
        orderContainsGift: siteGaData.checkout.orderContainsGift ? siteGaData.checkout.orderContainsGift : false, //ex. true, false
		orderShippingMethod: siteGaData.checkout.shipping, //ex. 5-7 business days, fedex smartpost
		orderShippingState: siteGaData.checkout.shippingState ? siteGaData.checkout.shippingState : undefined, //ex.ca, wa, or
		promoCodeSubmitted: 'promoCodeSubmitted' in siteGaData.checkout ? siteGaData.checkout.promoCodeSubmitted : undefined, //ex. family, new20, hannamade
		giftCardSubmitted: 'giftCardSubmitted' in siteGaData.checkout ? siteGaData.checkout.giftCardSubmitted : undefined, //ex. true, false
		clickLocation: location, //ex. mini-cart || cart
        freeShippingThresholdMet: threshold, //ex. yes, no
		freeShippingAmountLeft: remainingAmountUntilThreshold, //ex. $20
        ecommerce: {
            currency: siteGaData.currency,
            value: value,
            coupon: 'coupon' in siteGaData.checkout ? siteGaData.checkout.coupon : '',
            items: productViews
        }
    };

    switch (step) {
        case 1:
            checkoutData.event = 'begin_checkout'; // currently set as checkout-step for UA dataset
            delete checkoutData.orderShippingMethod;
            break;
        case 2:
            checkoutData.event = 'add_shipping_address'; // currently set as checkout-step for UA dataset
            delete checkoutData.orderShippingMethod;
            break;
        case 3:
            checkoutData.event = 'add_shipping_info';    // currently set as checkout-step for UA dataset
            checkoutData.ecommerce.shipping_tier = siteGaData.checkout.shipping || '';
            break;
        case 4:
            checkoutData.event = 'add_payment_info'; // currently set as checkout-step for UA dataset
            checkoutData.ecommerce.payment_type = siteGaData.checkout.billing || '';
            break;
        default:
            // Do nothing
            break;
    }

    sendEvent(checkoutData.event, checkoutData);
}

/**
 * Track products in an order and the order transaction
 * Pull data from window.siteGaData
 * Use that data to populate the 'products' and 'purchase'
 * Add data to the dataLayer which results in a GTM tag being fired
 */
function purchase() {
    if (!window.siteGaData) { 
        log('siteGa data does not exist or could not be serialized'); 
        return;
    }

    try {
        /** We need to normalize to avoid JS floating point issues */
        var revenue = parseFloat(siteGaData.order.revenue || 0) * 100;
        var shipping = parseFloat(siteGaData.order.shipping || 0) * 100;
        var tax = parseFloat(siteGaData.order.tax || 0) * 100;
        var value = revenue > 0 ? Math.floor(revenue - shipping - tax) / 100 : 0;
        var paymentMethods = Array.isArray(siteGaData.paymentMethod) ? siteGaData.paymentMethod : [siteGaData.paymentMethod].filter(function(val) {
            return val;
        });
        var paymentMethod = Array.from(new Set(paymentMethods.map(function(val) { 
            return val === 'STRIPE_PAYMENT_ELEMENT' ? 'CREDIT_CARD' : val;
        })));

        var gaData = {
            event: 'purchase',
            paymentMethod: paymentMethod,
            ecommerce: {
                currency: siteGaData.currency,
                transaction_id: siteGaData.order.id,
                value: value,
                coupon: siteGaData.order.coupon || '',
                shipping: siteGaData.order.shipping,
                tax: siteGaData.order.tax,
                items: []
            }
        };

        // any post-processing of the products can be done in
        // this loop before pushing to the dataLayer
        window.siteGaData.products.forEach(function(product, index) {
            gaData.ecommerce.items.push(getProductView(product, index));
        });

        // don't clear previous ecommerce object because we need transaction event data for tags triggered by completion of AX call.
        // dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push(gaData);
    } catch(e) {
        if (siteGaData && siteGaData.order && siteGaData.order.id) {
            log('failed to track purchase for orderNo: ' + siteGaData.order.id, e);
        } else {
            log('failed to track purchase', e);
        }
    }
}

/**
 * Track Promotion data
 * - if no data object is passed, scan the DOM for .site-ga-productslotdata
 * - if data is passed, use that to track specific promotions
 * @param {Event} _e Custom Event object
 * @param {Object} data Promotion Data to track - optional
 */
function viewproductslot(_e, data, product_list_name) {
    var eventType;
    if (data) {
        if (data.hasOwnProperty('type')) {
            eventType = data.type === 'promotion' ? 'view_promotion' : 'view_item_list';
        }

        sendEvent(eventType, $.extend({ product_list_name: product_list_name }, data ));
        return;
    }

    var $slots = $('.site-ga-productslotdata').not('.tracked');
    var $slot;
    var slotData;

    $slots.each(function(i, slot) {
        $slot = $(slot);

        /**
         * If we didn't receive a product_list_name, let's try to get one.
         */
        var checked_product_list_name = product_list_name || $slot.parent().find('h3').first().text();

        try {
            slotData = JSON.parse($slot.text());
        } catch(e) {
            log('promotion view GA data could not be serialized for analytics');
            return;
        }

        eventType = slotData.type === 'promotion' ? 'view_promotion' : 'view_item_list';
        if (eventType === 'view_item_list' && slotData.ecommerce && slotData.ecommerce.items) {
            var productViews = slotData.ecommerce.items.map(function(product, index) { 
                return getProductView(product, index, checked_product_list_name);
            });
            sendEvent(eventType, {
                product_list_name: checked_product_list_name,
                ecommerce: {
                    items: productViews
                }
            });
        } else {
            sendEvent(eventType, slotData);
        }

        // add class to prevent duplicate tracking
        // preserve the data for future select_promotion/select_item tracking
        $slot.addClass('tracked');

        // add tracking handles for link click, report that product's data
        // as long as the link or one of its parents was tagged with 'data-itemid'
        var $trackingParent = $slot.parents('[data-analytics-track-selection]:first');
        if ($trackingParent.length) {
            $trackingParent.on('click', 'a', function() {

                // get this link's product data
                var itemData = getElementViewItemData($(this), slotData.ecommerce, checked_product_list_name);
                if (!itemData) { return; }

                var eventType = slotData.type === 'promotion' ? 'select_promotion' : 'select_item';

                if (eventType === 'select_item') {
                    /**
                     * We do this to persist data for selected items
                     * through order confirmation in our reporting.
                     */
                    setProductListInStorage(itemData.item_id, checked_product_list_name);
                    setProductCategoryInStorage(itemData.item_id, [
                        itemData.item_category,
                        itemData.item_category2,
                        itemData.item_category3,
                        itemData.item_category4
                    ].filter(function(val) { 
                        return val;
                    }).join(' / '));
                    setProductLoadListNumberInStorage(itemData.item_id, itemData.product_list_load_number);
                }

                const clickedObj = _e.currentTarget.activeElement;
                const recommender = $(clickedObj)[0].closest('.pdp-lite-recommender');
                let recommenderTier = "0";
                let recommenderPosition = "0";
                let recommenderClick = false;
                if(recommender != null){
                    recommenderClick = true;
                    recommenderPosition = $(clickedObj)[0].closest('.pdpLiteRecommender').className.split(' ')[1].substring(4, 5);
                    if($(recommender).hasClass('tier-1')){
                        recommenderTier = "1";
                    } else if($(recommender).hasClass('tier-2')){
                        recommenderTier = "2";
                    } else if($(recommender).hasClass('tier-3')){
                        recommenderTier = "3";
                    }
                }

                sendEvent(eventType, {
                    product_list_name: checked_product_list_name,
                    "recommenderCLick": recommenderClick,
                    "recommenderTier" : recommenderTier,
                    "recommenderPosition" : recommenderPosition,
                    ecommerce: {
                        items: [itemData]
                    }
                });
            });
        }

    });
}

/**
 * Report gift card applied to order
 * @param {Event} event custom event
 * @param {String} cardNumber gift card number
 * @param {boolean} success gift card success status
 */
function applyGiftCard(event, cardNumber, success) {
	if (!cardNumber) { return; }

    sendEvent('apply-gift-card', {
        giftCardNumber: cardNumber,
        giftCardStatus: success ? 'success' : 'fail',
    });
}

/**
 * Report PDP shop the look click
 * @param {Event} event custom event
 * @param {String} productID
 * @param {String} productName
 */
function shopTheLook(event, productID, productName) {
    sendEvent('shop-the-look', {
        productSku: productID,
        productName: productName
    });
}

/**
 * Report Top Navigation Click
 * @param {String} classification
 * @param {String} division
 * @param {String} category
 * @param {String} text
 * @param {String} type
 */
function topNavigationClick(classification, division, category, text, type) {
    sendEvent('top_navigation_click', {
        link_classification: classification,
        link_division: division,
        link_category: category,
        link_name: text,
        link_type: type
    });
}

/**
 * Report navigation utility click
 * @param {String} classification
 * @param {String} division
 * @param {String} category
 * @param {String} text
 * @param {String} type
 */
function navigationUtilityClick(classification, division, category, text, type) {
    sendEvent('navigation_utility', {
        link_classification: classification,
        link_division: division,
        link_category: category,
        link_name: text,
        link_type: type
    });
}

/**
 * Report account activity
 * @param {String} accountCategory
 * @param {String} accountSection
 */
function myAccountActivityClick(accountCategory, accountSection, clickText, accountSubcategory, accountSubsection, clickPosition) {
    sendEvent('my-account-activity', {
        accountCategory: accountCategory,
        accountSubcategory: accountSubcategory,
        accountSubsection: accountSubsection,
        accountSection: accountSection,
        clickText: clickText,
        clickPosition: clickPosition,
    });
}

/**
 * Report click on search result
 * @param {String} searchTerm
 * @param {String} productName
 */
function searchResultClick(searchTerm, productName) {
    sendEvent('search-result-click', {
        searchTerm: searchTerm,
        searchResult: productName
    });
}

/**
 * Report cart engagement
 * @param {String} clickText
 * @param {String} clickSection
 */
function cartEngagement(clickText, clickSection) {
    sendEvent('cart-engagement', {
        clickText: clickText,
        clickSection: clickSection
    });
}

/**
 * Report checkout engagement
 * @param {String} clickText
 * @param {String} clickSection
 * @param {String|Number} checkoutStep
 */
function checkoutEngagement(_event, clickText, clickSection, checkoutStep) {
    sendEvent('checkout-engagement', {
        clickText: clickText,
        clickSection: clickSection,
        checkoutStep: '' + checkoutStep
    });
}

/**
 * There is a bug in swiper slider when breakpoints are used.
 * It causes multiple events to be fired for navigation events,
 * which in turn causes the below event to fire too often.
 * 
 * So we attempt to prevent too many events firing.
 */
var isRecommendationEventFired = false;

/** Recommender slider engagement
 * @param {String} arrowDirection
 * @param {String} sliderName
 */
function recommenderSliderEngagement(event, arrowDirection, sliderName) {
    if (!isRecommendationEventFired) {
        isRecommendationEventFired = true;
        sendEvent('recommender-slider', {
            arrowDirection,
            sliderName,
        });
    }

    /** We set a timeout to revert our flag, so we can fire events again */
    setTimeout(function() {
        isRecommendationEventFired = false;
    }, 300);
}

/**
 * @private
 * Handle add/remove/view cart and add to wishlist events
 * @param {String} eventName dataLayer event name
 * @param {Array} products list of products to send
 */
function addRemoveCartEvents(eventName, products, clickLocation) {
    if (!siteGaData || !products) { return; }

    var productViews = [];
    var value = 0;
    products.forEach(function(product, index) {
        var productView = getProductView(product, index);
        value += productView.price * productView.quantity;
        productViews.push(productView);
    });

    sendEvent(eventName, {
        ecommerce: {
            currency: siteGaData.currency,
            value: value,
            items: productViews,
        },
        ...(clickLocation ? { clickLocation } : {}),
    });
}


/**
 * @private
 * @param {jQuery} $el jQuery element to get data from (or its parents)
 * @param {Object} viewItemData GA data with items[] array
 * @returns {Object|undefined} Ga data for the item, undefined if an item could not be found
 */
function getElementViewItemData($el, viewItemData, product_list_name) {
    var itemID = $el.data('itemid') || $el.parents('[data-itemid]:first').data('itemid');
    if (!itemID) { return undefined; }

    itemID = itemID.toString();

    for (var i = 0; i < viewItemData.items.length; i++) {
        if (viewItemData.items[i].item_id === itemID || viewItemData.items[i].variant === itemID) {
            return getProductView(viewItemData.items[i], i, product_list_name);
        }
    }

    return undefined;
}

/**
 * The following functions are duplciates of what is found in the family
 * match tracking. This seems preferable to trying to share state between
 * multiple, separate JS bundles via some intermediary.
 * 
 * NOTE: If / when this set of functions is merged into the primary bundle
 * we should use the family match tracking functions we've exposed.
 */
function getAnchorIdParam() {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('anchorId') || '';
}

function getCurrentProductSetFromPath() {
    const pathname = window.location.pathname || '';
    const pathPieces = pathname.split('/');
    const lastPiece = pathPieces.slice(-1)[0] || '';
    
    const filePieces = lastPiece.split('.');
    
    return filePieces[0] || '';
}

function getAnchorId() {
    const anchorIdParam = getAnchorIdParam();

    if (anchorIdParam) {
        return anchorIdParam;
    }

    return getCurrentProductSetFromPath();
}

function getIdIfFamilyMatch() {
    const anchorIdParam = getAnchorId();

    if (!anchorIdParam) {
        return;
    }

    /** Family match pages have a 5 character page id */
    if (anchorIdParam.length === 5) {
        return anchorIdParam;
    }
}

/**
 * @private
 * Update child items in family match based on parent.
 * @param {String} familyMatchId
 * @param {String} itemID
 */
function updateFamilyMatchChildProducts(familyMatchId, itemId) {
    try {
        if (familyMatchId && itemId) {
            const parentCategoryInformation = getProductCategoryFromStorage(familyMatchId);
            if (parentCategoryInformation) {
                setProductCategoryInStorage(itemId, parentCategoryInformation);
            }

            const parentProductListName = getProductListFromStorage(familyMatchId);
            if (parentProductListName) {
                setProductListInStorage(itemId, parentProductListName);
            }

            const parentProductListLoadNumber = getProductLoadListNumberFromStorage(familyMatchId);
            if (parentProductListLoadNumber) {
                setProductLoadListNumberInStorage(itemId, parentProductListLoadNumber);
            }
        }
    } catch (err) {}
}

/**
 * @private
 * Get product information to pass to GA
 * @param {Object} product
 * @param {Number} index
 * @param {String} product_list_name
 * @returns {Object}
 */
function getProductView(product, index, product_list_name) {
    if (!product) { return {}; }

    var familyMatchPath = getIdIfFamilyMatch();
    var familyMatchCategory = getProductCategoryFromStorage(familyMatchPath);
    if (familyMatchCategory) {
        updateFamilyMatchChildProducts(familyMatchPath, product.id);
    }
    var categories = getProductCategoryFromStorage(familyMatchPath) 
        || getProductCategoryFromStorage(product.id)
        || product.category 
        || '';
    var categoryList = categories.split(' / ');
    var price = parseFloat(product.price);
    var standardPrice = parseFloat(product.standardPrice);
    var productListNameFromStorage = getProductListFromStorage(product.id);
    var productView = {
        affiliation: product.affiliation,
        item_id: product.id,
        item_name: product.name,
        currency: siteGaData.currency,
        index: index || 0,
        item_brand: product.brand || '',
        item_category: categoryList[0] || '',
        item_category2: categoryList[1] || '',
        item_category3: categoryList[2] || '',
        item_category4: categoryList[3] || '',
        item_variant: product.variant,
        item_color: product.colorName,
        price: !isNaN(price) ? price : product.price,
        quantity: product.quantity || 1,
        item_list_name: product_list_name || productListNameFromStorage || product.dimension7 || product.list || '',
        family_product_sku: product.id,
        product_list_load_number: getProductLoadListNumberFromStorage(product.id) || product.dimension2,
        product_list_price: !isNaN(standardPrice) ? standardPrice : product.standardPrice,
        product_division: product.dimension4,
        product_category: product.dimension5,
        product_subcategory: product.dimension6,
        product_classification: product.dimension7,
        new_product_status: product.dimension8,
        product_rating: product.reviewRating,
        number_product_reviews: product.reviewCount,
        product_stock_status: product.dimension11,
        pdp_type: product.dimension12,
        quick_buy_pdp: product.dimension13,
        number_of_product_images: product.dimension14,
        product_size: product.dimension15,
        product_is_gift: product.dimension16,
        variant: product.colorName,
        skuATS: product.skuATS,
        offerATS: product.offerATS,
        tierOneMsg: product.tierOneMessaging,
        position: product.position
    };

    return productView;
}

/**
 * @private
 * Get a unique ID of the product impression
 * This is a utility function to use when determining when to report
 * impressions and when to filter out duplicates
 * @param {Object} product impression object to generate unique ID
 */
function getImpressionUniqueID(product) {
    return product.variant && product.variant.length ? product.variant : product.id;
}

/**
 * @private
 * Utility log message, send to server to log
 * @param {String} msg message to send to log
 * @param {Object} data object
 * @param {String} level 'warn', 'error', or 'debug'
 */
function log(msg, data, level) {
    var payload = {};

    payload = {
        msg: msg,
        data: JSON.stringify(data) || '',
        level: level || 'warn'
    };

    $.ajax({
        method: 'GET',
        dataType: 'json',
        url: window.siteGaData.resources.logUrl,
        async: true,
        data: payload
    });
}

module.exports = {
    add_to_cart: add_to_cart,
    add_to_wishlist: add_to_wishlist,
    applyGiftCard: applyGiftCard,
    cartEngagement: cartEngagement,
    checkoutEngagement: checkoutEngagement,
    checkoutStep: checkoutStep,
    myAccountActivityClick: myAccountActivityClick,
    navigationUtilityClick: navigationUtilityClick,
    productTilesAjax: productTilesAjax,
    purchase: purchase,
    quickview: quickview,
    recommenderSliderEngagement: recommenderSliderEngagement,
    remove_from_cart: remove_from_cart,
    select_item: select_item,
    searchResultClick: searchResultClick,
    sendEvent: sendEvent,
    shopTheLook: shopTheLook,
    topNavigationClick: topNavigationClick,
    view_cart: view_cart,
    view_item: view_item,
    view_item_list: view_item_list,
    viewproductslot: viewproductslot,
};
